var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-screen flex w-full bg-img" }, [
    _c(
      "div",
      {
        staticClass:
          "vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center",
      },
      [
        _c("vx-card", [
          _c(
            "div",
            {
              staticClass: "full-page-bg-color",
              attrs: { slot: "no-body" },
              slot: "no-body",
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center",
                  },
                  [
                    _c("img", {
                      staticClass: "mx-auto",
                      attrs: {
                        src: require("@/assets/images/pages/forgot-password.png"),
                        alt: "login",
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg",
                  },
                  [
                    !_vm.verficatonSuccessful
                      ? _c("div", { staticClass: "p-8" }, [
                          _c(
                            "div",
                            { staticClass: "vx-card__title mb-8 w-full" },
                            [
                              _c(
                                "tp-cardtext",
                                { attrs: { "min-height": 165 } },
                                [
                                  _c("p", [
                                    _vm._v(
                                      "Email verification link has expired or invalid. Please request a new confirmation email in account page."
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "vs-button",
                                {
                                  staticClass:
                                    "float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-6",
                                  attrs: { type: "border", to: "/account" },
                                },
                                [_vm._v("Account")]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.verficatonSuccessful
                      ? _c("div", { staticClass: "p-8" }, [
                          _c(
                            "div",
                            { staticClass: "vx-card__title mb-8 w-full" },
                            [
                              _c("h4", { staticClass: "mb-4" }, [
                                _vm._v("Email Has Been Confirmed"),
                              ]),
                              _c(
                                "tp-cardtext",
                                { attrs: { "min-height": 150 } },
                                [
                                  _c("p", [
                                    _vm._v("Your email has been confirmed."),
                                  ]),
                                ]
                              ),
                              _c(
                                "vs-button",
                                {
                                  staticClass:
                                    "float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-6",
                                  attrs: { type: "border", to: "/" },
                                },
                                [_vm._v("Go to home page")]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }